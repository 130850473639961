<template>
  <v-card @keydown.ctrl.enter="createContact" :ripple="false">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select :items="contactTypes" hide-details filled label="Тип контакту" v-model="contact_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="contact_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Значення контакту" v-model="contact_value"
                          required hide-details
                          :rules="contact_type === 'email' ? emailRules : phoneRules"
                          :class="contact_value ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createContact" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";
import {
  CREATE_MAIN_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  FETCH_MAIN_CONTACTS
} from "@/store/actions/contact";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import { contactTypeSelect } from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'contact_modal_delete'

export default {
  name: "HWP_Modal_Contacts",
  mixins: [ModalComponentMixin],
  data() {
    return {
      family_hash: this.item.family_hash,
      contact_type: this.item.contact_type,
      contact_value: this.item.contact_value,
      contact_main: this.item.contact_main,
      person_hash: this.item.person_hash,
      contactTypes: contactTypeSelect,
      emailRules: [
        v => !!v || 'Це поле э обов’язковим',
        v => /.+@.+/.test(v) || 'Неправильний email',
      ],
      phoneRules: [
        v => !!v || 'Це поле э обов’язковим',
        v => /^(?:\+380|380|0)\d{2}\d{7}/.test(v) || 'Неправильний телефон (380|80|0)XXXXXXXXX',
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchMainContacts: FETCH_MAIN_CONTACTS
    }),
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.family_hash = this.item.family_hash
      this.person_hash = this.item.person_hash
      this.contact_type = this.item.contact_type
      this.contact_value = this.item.contact_value

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення контактних даних ${this.contact_type}: ${this.contact_value}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createContact() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Тип контакту та Значення контакту - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        family_hash: this.family_hash,
        contact_type: this.contact_type,
        contact_value: this.contact_value,
        person_hash: this.person_hash
      }

      if (this.isNew) {
        const action = this.contact_main ? CREATE_MAIN_CONTACT : CREATE_CONTACT
        this.$store.dispatch(action, payload)
            .then(res => {
              if (res) {
                this.closeModal()
                this.fetchMainContacts(this.$route.params.person_hash)
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CONTACT, payload)
            .then(res => {
              if (res) {
                this.closeModal()
                this.fetchMainContacts(this.$route.params.person_hash)
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.family_hash = payload.family_hash
              this.person_hash = payload.person_hash
              this.contact_type = payload.contact_type
              this.contact_value = payload.contact_value
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_CONTACT, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
